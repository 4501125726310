@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.video-player-container {
  @include mixins.display-flex-column;
  position: relative;
  z-index: 1;
  width: fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  overflow-y: scroll;
  overflow-x: hidden;

  .video-container {
    width: auto;
    aspect-ratio: 16 / 9;
    height: 55vh;

  }

  .transcript-container {
    align-self: stretch;
    background: #FFFFFF;
    height: fit-content;
    opacity: 0.9;
    margin-top: 0.5vh;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 14vh;

    .transcript-body {
      padding: 2vh 2.5vw 5vh 2.5vw;
      width: 44vw;

      .t-title {
        font-family: Roboto;
        font-weight: 400;
        font-size: 1.2vw;
        text-transform: uppercase;
        line-height: 1.3vw;
        padding-bottom: 1.5vh;
        color: #0D54BD;
      }

      .t-desc {
        width: auto;
        font-family: Roboto;
        font-weight: 400;
        font-size: 1.2vw;
        line-height: 1.5vw;
        opacity: 0.8;
        color: #1E2734;
        word-wrap: normal;
      }
    }
  }

  .proceed-btn-container {
    @include mixins.display-flex-center;
    position: fixed;
    width: 49.5vw;
    margin-left: auto;
    margin-right: auto;
    bottom: 1vh;
    align-self: stretch;
    background: #FFFFFF;
    height: fit-content;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 2vh;

    .proceed-btn {
      background: #FBEC66;
      width: 15vw;
      height: 5vh;
      border-radius: 40px;
      border: none;
      font-size: 1.1vw;
      font-family: Roboto;
      font-weight: 500;
      color: #0D54BD;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      margin: 2vh 0 2vh 0;
      cursor: pointer;
    }

    .proceed-btn:hover {
      box-shadow: 0px 0 4px rgba(0, 0, 0, 0.25);
    }

    .disable-btn {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}