@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.intro-icon-container {
  @include mixins.display-flex-start;
  cursor: pointer;
  
  .icon-label {
    padding-left: 4px;
    color: white;
    font-size: 0.9vw;
  }
}