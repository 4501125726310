@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.fake-div {
  //fake-div to prevent style of pcontainer affect another pcontainer classes
  
  .pcontainer {
    min-height: 46vh;
    min-width: 47vw;
    top: 7vmin;

  @supports (height: -moz-fit-content) { /* workaround because firefox doesn't support height: auto with height: fit-content; */
    .pcontainer {
        height: 52vmin;
    }
   }
    
    
    .phead {
      height: 9.5vh;
      .action-name {
        @include mixins.display-flex-center;

        .action-icon {
          width: 1.6vw;
          padding-right: 0.8vw;
        }

        .action-title {
          line-height: 1.8vh;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          letter-spacing: 0.1vw;
          font-size: 1.2vw;
          color: #FFFFFF;
          padding-right: 0.8vw;
          border-right: 1px solid #FFFFFF;
          margin-right: 0.8vw;
        }

        .current-day {
          font-family: Roboto;
          font-size: 1.2vw;
          font-weight: 300;
          letter-spacing: 0.05vw;
          color: #FFFFFF;

        }
      }
    }

    .arbody {
      @include mixins.display-flex-column;
      width: 47vw;
      min-height: 36.5vmin;
      padding-left: 0;
      padding-bottom: 0;
      // height: fit-content;
      overflow-y: auto;
      padding: 0 4vw 2vw 4vw;

      .action-characters {
        @include mixins.display-flex-center;
        width: auto;
        height: 7vh;
        padding-top: 2vmax;

        .from-character {
          width: 3.4vw;
          padding-right: 0.8vw;
        }

        .arrow-right {
          width: 4.5vw;
          padding-right: 0.8vw;
        }


        .to-character {
          width: 3.4vw;
          padding-right: 0.8vw;
        }
        
      }

      .action-res {
        @include mixins.display-flex-column;
        @include mixins.display-flex-space-between;
        height: fit-content;
        width: 90%;
        align-self: center;
        padding-top: 2vmax;
        padding-bottom: 2vh;

        .res-title {
          font-weight: 500;
          font-family: Roboto;
          color: #0D54BD;
          font-size: 1.1vw;
          padding-bottom: 2vh;
        }

        .indirect-res-title {
          font-weight: 500;
          font-family: Roboto;
          color: #0D54BD;
          font-size: 1.1vw;
          padding: 1vh 0 2vh 0;
        }

        .indirectly-impacted {
          width: 90%;
          @include mixins.display-flex;
          padding-bottom: 2vh;

          .indirect-res-card {
            @include mixins.display-flex-column;
            align-items: center;
            padding: 1vh 0 1vh 1vw;

            .res-character {
              padding-bottom: 1vh;
            }
          }
        }

        .indirect-tooltip {
          width: 37vw;
          padding-bottom: 2vh;

          .tooltip-container {
            width: 100%;
            padding: 1vh 0.5vw 1vh 0.5vw;
          }
        }

        .res-card {
          @include mixins.display-flex-space-between;
          padding: 1vh 0 1vh 1vw;

          
          .triangle {
            position: absolute;
            content: '';
            display: block;  
            margin-top: -3vh;
            margin-left: -0.5vw;
            left: -1vw;   
            width: 0;
            height: 0;
            border-top: 1.5vh solid transparent;
            border-right: 1.5vh solid #F5F5F5;
            border-bottom: 1.5vh solid transparent;
            border-left: 1.5vh solid transparent;
          }

          .res-desc {
            @include mixins.display-flex-center;
            width: 24vw;
            align-self: stretch;
            position: relative;
            background: #FFFFFF;
            box-sizing: border-box;
            box-shadow: 0px 2px 4px #E7EFFE;
            border-radius: 6px;
            padding: 1vh 0.5vw 1vh 0.5vw;
            font-family: Roboto;
            font-size: 1.1vw;
            font-weight: 400;
            color: #1E2734;
          }
        }

        .actor-first-name {
          padding: 0.5vh 0.5vw 0 0.5vw;
          font-family: Roboto;
          color: #0D54BD;
          text-align: center;
          font-size: 1vw;
          font-weight: normal;
        }

        .res-character {
          width: 5vw;
          height: 5vw;
        }

        .res-impact {
          @include mixins.display-flex-column;
          background: #FFFFFF;
          width: 9vw;
          margin-top: auto;
          margin-bottom: auto;
          border-radius: 6px;
          box-shadow: 0px 2px 4px #E7EFFE;
          align-self: stretch;

          

          .impact-body {
            @include mixins.display-flex-column;
            padding-left: 1vw;
            padding-right: 1vw;

            .impact-each {
              width: 100%;
              @include mixins.display-flex-space-between;
              align-items: center;
              padding-bottom: 1vh;
              .impact-label {
                font-family: Roboto;
                font-size: 0.95vw;
                color: #0D54BD;
              }

              .ty {
                color: #FC8484;
                font-weight: bold;
                font-size: 0.95vw;
                .percentage {
                  font-size: 0.7vw;
                }
              }

              .impact-indicator {
                @include mixins.display-flex-center;
                width: 3.5vw;
                height: 2.7vh;
                align-items: center;
                border-radius: 10px;

                .indicator-arrow {
                  padding-left: 0.5vw;
                }
              }
            }
          }

          
        }
      }

      .close-popup-btn {
        align-self: center;
        background: #FBEC66;
        border-radius: 20px;
        height: 4vh;
        width: 15vw;
        margin-top: auto;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        color: #0D54BD;
        font-size: 1.1vw;
        font-weight: 500;
        margin-bottom: 3vh;
        cursor: pointer;
      }

      .pfooter {
        width: auto;
      }
    }
  }
}