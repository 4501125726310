.days {
  color: white;
  // margin-left: -10%;
  font-size: 1.3vw;
  
  .current-day {
    font-weight: 500;
    line-height: 21px;
    font-size: 1.2vw;
  }

  .max-day {
    opacity: 0.5;
    font-size: 0.8vw;
  }
}