@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.single-action-clicked {
  background: linear-gradient(341.24deg, #EFF5FF 12.68%, #FFFFFF 87.32%);
}

.pointer-events-none {
  pointer-events: none;
}

.opacity-4 {
  opacity: 0.4;
}

.individual-actions-container {
  @include mixins.display-flex-column;
  height: 100%;
  justify-content: center;
  width: fit-content;
  display: table;  /*add this line for edge*/

  .individual-actions {
    @include mixins.display-flex-column;
    @include mixins.display-flex-start;
    width: fit-content;
    height: 95%;
    font-family: Roboto;
    font-size: 1.7vh;
    font-weight: 400;
    color: #0D54BD;
    margin-top: 2vh;
  
    .title {
      @include mixins.border-horizontal-blue;
      font-weight: 400;
      font-size: 1.1vw;
      align-self: stretch;
      border-top-width: 0px;
      border-left-width: 0px;
      border-right-width: 0px;
      border-bottom: 1px solid;
      background-clip: content-box;
      padding-bottom: 1.5vh;
    }
    .all-actions{
      @include mixins.display-flex;
      justify-content: flex-start;
      height: 90%;
      padding-right: 1vw;
      .actions {
        @include mixins.display-flex-column;
        @include mixins.display-flex-space-between;
        width: fit-content;
        display: table;  /*add this line for edge*/
        height: 85%;
        padding-top: 3vh;
        padding-right: 1vw;
        align-self: stretch;
        text-transform: uppercase;
        
        .single-action {
          @include mixins.display-flex-start;
          width: 12vw;
          border-radius: 15px;
          margin-bottom: 1vmin;

          .action-name {
            @include mixins.display-flex;
            align-items: center;
            height: 5vh;
            width: fit-content; //to make only the action name clickable
            padding-left: 0.5vw;
            font-size: 0.95vw;
            font-weight: 400;
            cursor: pointer;
          }
        }
      }

      .individual-action-description {
        background: linear-gradient(341.24deg, #EFF5FF 12.68%, #FFFFFF 87.32%);
        filter: drop-shadow(0px 4px 14px rgba(1, 44, 154, 0.05));
        box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.05);
        border-radius: 5%;
        width: 15.5vw;
        min-height: 55vh;
        height: fit-content;

        .description-container {

          .select-team-members {
            padding-bottom: 0
          }
        }
        .take-action-button {
          margin-top: inherit;
        }
      }
    }

    .locked-individual-actions {
      @include mixins.display-flex-center;
      position: absolute;
      top: 50%;
      width: 4.5vw;
      height: 4.5vw;
      transform: translateY(-50%);
      align-items: center;
      background: linear-gradient(92.13deg, #F1F5F9 0.83%, #FFFFFF 100%);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
      border-radius: 50%;
      
      .lock-icon {
        align-self: center;
        width: 1.3vw;
      }
    }
  }
}
