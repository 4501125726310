@use 'src/styles/_colors.scss';

.client-container svg {
    width: 40vw;
    height: 5vh;
    line{
        stroke-width: 0.15vh;
        border: 0.15vh solid;
        border-image-slice: 1;
    }
}