@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.icon-container {
  @include mixins.display-flex-column;
  @include mixins.display-flex-center;
  height: 100%;
  width: 5vw;
  cursor: pointer;
  padding: 0 1vw;

  .metric-icon {
    display: flex;
    height: 3vw;
    width: 3vw;
    background: rgba(255, 255, 255, 0.06);
    border-radius: 50%;
    justify-content: center;
  }

  .metric-label {
    font-weight: 500;
    font-size: 0.6vw;
    color: white;
    line-height: 2vw;
  }

  .tooltiptext{
    display: none;
    background: #fff;
    font-family: Roboto;
    color: colors.get-color("blue");
    font-size: colors.get-font("m");
    font-weight: 500;
    line-height: 1.4vmax;
    padding: 1.3vmax;
    top: 15%;
    right: 14vmax;
    width: 15vw;
    border-radius: 1vmax;
  }
}

.report_lock:hover .tooltiptext {
  display: block;
  position: absolute;
  pointer-events: none;
}
.report_lock .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 22%;
  margin-left: -1vmax;
  border-width: 1vmax;
  border-style: solid;
  border-color: transparent transparent white transparent;
}