@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.logout {
  @include mixins.display-flex-center;
  width: 100%;
  height: 100%;
  pointer-events: all;
  z-index: 10;

  .logout-label {
    width: 6vw;
    height: 60%;
    color: #FFF;
    font-size: 0.9vw;
    padding-right: 0.5vw;
    word-break: break-all;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
  }
  img{
    cursor: pointer;
  }
}

.pcontainer .popup-close {
  pointer-events: all;
}

.intro-logout{
  margin-left: auto;
  margin-right: 1.5vw;
  color: colors.get-color("blue");
  display: flex;
  flex-direction: row;

  .logout-label{
    align-self: center;
    font-weight: 500;
    font-family: Roboto;
    font-size: colors.get-font("m");
    margin-right: 0.4vw;
  }
  img{
    cursor: pointer;
  }
}
.lbuttons{
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 45%;
  justify-content: space-around;
  padding-top: 2vh;
  
  .logout-btn{
    background-color: colors.get-color("yellow");
    border-radius: 2vh;
    border: none;
    padding: 1vh 1vw;
    font-family: Roboto;
    font-size: 1.8vh;
    color: colors.get-color("blue");
    cursor: pointer;
  }
  .cancel{
    background-color: colors.get-color("white");
    border-radius: 2vh;
    border: none;
    padding: 1vh 1vw;
    font-family: Roboto;
    font-size: 1.8vh;
    color: colors.get-color("blue");
    cursor: pointer;
  }
}