@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.header-container {
  display: flex;
  justify-content: flex-start;
  width: 100vw;
  align-items: center;
  margin: 1.5vh 0 1.5vh 0;

  .sim-logo-container {
    @include mixins.display-flex-center;
    // padding-right: 1vw;
    width: 13vw;
    
    .bold {
      font-weight: 700;
      font-size: 1.7vw;
      line-height: 14px;
      
      &.white{
        color: white;
      }
    }

    .yellow {
      font-style: italic;
      font-weight: 300;
      font-size: 1.7vw;
      color: yellow;
    }
  }

  .divider {
    border-image: linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1),rgba(255,255,255,0));
    opacity: 0.5;
    border-image-slice: 1;
    background-clip: content-box;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
  }

  .overlay-meter-cutout {
    position: absolute;
    left: 15vw;
    width: 30vw;
    height: 13vh;
    border-radius: 35px;
    background: linear-gradient(152.84deg, #2E78BD 28.78%, #054B7D 73.94%), linear-gradient(180deg, #EDEDED 2.44%, #FFFFFF 100%);
    z-index: 6;
  }
  
  .trust-meter {
    width: 28.5vw;
    height: auto;
    align-self: stretch;
  }

  .timer-cutout {
    position: absolute;
    top: 0.5vh;
    left: 46vw;
    width: 10vw;
    height: 15vh;
    background: linear-gradient(152.84deg, #2E78BD 28.78%, #054B7D 73.94%), linear-gradient(180deg, #EDEDED 2.44%, #FFFFFF 100%);
    border-radius: 1.8vw;
    z-index: 9;
  }

  .timer-container {
    @include mixins.display-flex-column;
    @include mixins.display-flex-space-between;
    width: 8vw;
    height: auto;
    align-self: stretch;
    align-items: center;
    padding-left: 2vw;
    padding-right: 2.5vw;

    .timer {
      @include mixins.display-flex-space-evenly;
      justify-content: space-around;
      width: 100%;
      height: 50%;
      background-color: rgba(0,0,0,0.3);
      align-items: center;
      border: 1px solid #FFFFFF;
      border-radius: 2.5vw;
      z-index: 4
    }
    .calendar {
      @include mixins.display-flex-space-evenly;
      width: 100%;
    }
    
  }
  .calendar-container {
    @include mixins.display-flex-space-evenly;
    width: 10%;
  }

  .metric-container {
    @include mixins.display-flex-space-between;
    width: auto;
    height: auto;
    align-self: stretch;
  }
  
  .intro-icons-container {
    @include mixins.display-flex-column;
    @include mixins.display-flex-space-evenly;
    // width: 8vw;
    height: auto;
    align-self: stretch;
  }

  .intro-icons-container::after,
  .intro-icons-container::before{
      display: none;
  }

  .logout-container {
    @include mixins.display-flex-center;
    width: 8vw;
    height: auto;
    align-self: stretch;
    margin-left: auto;
    margin-right: 1.5vw;
  }
}