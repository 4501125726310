@import "fonts/fonts.scss";

@mixin display-flex {
    display: flex
}
    
@mixin display-flex-column{
    display: flex;
    flex-direction: column;
}

@mixin display-flex-column-reverse{
    display: flex;
    flex-direction: column-reverse;
}

@mixin display-flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin display-flex-start{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

@mixin display-flex-space-between{
    display: flex;
    justify-content: space-between;
}

@mixin display-flex-space-around{
    display: flex;
    justify-content: space-around;
}

@mixin display-flex-space-evenly{
    display: flex;
    justify-content: space-evenly;
}

@mixin display-flex-end-vertical{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

@mixin display-flex-end{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@mixin display-flex-horizontal-center{
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

@mixin display-flex-horizontal-start{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

@mixin padding-left-right-zero{
    padding-left: 0;
    padding-right: 0;
}

/* font style mixins */


/* border style mixins */
@mixin border-vertical-blue {
    border-image: linear-gradient(to top, rgba(5, 75, 125, 0), rgba(5, 75, 125, 1),rgba(5, 75, 125, 0));
    border-image-slice: 1;
}

@mixin border-horizontal-blue {
    border-image: linear-gradient(to right, rgba(5, 75, 125, 0), rgba(5, 75, 125, 1),rgba(5, 75, 125, 0));
    border-image-slice: 1;
}

// 1.1 vw =  14px
// 1.4vw =  18px
// 0.9vw = 11.52px
// 1vw =  12.8px