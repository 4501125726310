@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.reps-container{
    // padding: 0.5vh 2vw;
    display: grid;
    grid-gap: 0.1vw;
    grid-template-columns: repeat(2, 1fr);
    height: 100%;
    background-color: #fff;
    border-radius: 1vw;

    .scholar{
        border-top-left-radius: 1vw;
        font-weight: 500;
    }
    .relator{
        border-top-right-radius: 1vw;
        font-weight: 500;
        img{
            float: right;
            margin-right: -1.3vw;
            margin-top: -0.6vw;
        }
    }
    .executor{
        border-bottom-left-radius: 1vw;
        font-weight: 500;
    }   
    .promoter{
        border-bottom-right-radius: 1vw;
        font-weight: 500;
    }
}
.reps-container > div {
    background-color: colors.get-color("dark-blue");
    color: #fff;
    padding: 2vh 2vw;
}
.reps-container .desc{
        font-size: 1.5vh;
        font-family: Roboto;
        padding-top: 1.7vh;
        line-height: 2.7vh;
}