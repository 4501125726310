@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.event_container {
  @include mixins.display-flex;
  z-index: 6;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 55vh;
  background: #FFFFFF;

  .body {
    @include mixins.display-flex-column;
    width: 65vw;
    height: 43vh;
    padding-left: 5.5vw;
    padding-top: 5vh; 
    padding-right: 2.5vw;

    .title {
      padding-top: 0.5vh;
      font-family: Roboto;
      font-size: 1.5vw;
      font-weight: 500;
      color: #0D54BD;
    }

    .desc {
      padding-top: 2vh;
      font-family: Roboto;
      font-size: 1.2vw;
      line-height: 2vw;
      color: rgba(30, 39, 52, 0.8);
    }

    .msg_options {
      @include mixins.display-flex-column;
      padding-top: 2vh;
      padding-left: 0.5vw;

      .individual_msg {
        @include mixins.display-flex;
        font-family: Roboto;
        font-weight: 500;
        font-size: 1.3vw;
        color: rgba(30, 39, 52, 0.8);
        line-height: 2.3vw;

        .radio {
          padding: 0;
          padding-right: 1vw;
        }

        .msg_text {
          cursor: pointer;
        }
      }

      .individual_msg:hover {
        background: rgba(13, 84, 189, 0.04);
      }
    }

    .event_res {
      @include mixins.display-flex;
      padding-top: 1vh;

      .card {
        @include mixins.display-flex-column;
        padding-right: 1vw;

        .char {
          width: 5vw;
          height: 5vw;
          align-self: center;
          padding-bottom: 1vh;
        }

        .impact {
          @include mixins.display-flex-column;
          background: #FFFFFF;
          width: 9vw;
          border-radius: 6px;
          box-shadow: 0px 2px 4px #E7EFFE;
          align-self: stretch;

          .first_name {
            padding: 2px 0.5vw 1vh 0.5vw;
            font-family: Roboto;
            color: #0D54BD;
            text-align: center;
            font-size: 1vw;
            font-weight: normal;
          }

          .impact_body {
            @include mixins.display-flex-column;
            padding-left: 1vw;
            padding-right: 1vw;

            .each {
              width: 100%;
              @include mixins.display-flex-space-between;
              align-items: center;
              padding-bottom: 1vh;

              .label {
                font-family: Roboto;
                font-size: 0.95vw;
                color: #0D54BD;
              }

              .ty {
                color: #FC8484;
                font-weight: bold;
                font-size: 0.95vw;

                .percentage {
                  font-size: 0.7vw;
                }
              }

              .impact-indicator {
                @include mixins.display-flex-center;
                width: 3.5vw;
                height: 2.7vh;
                align-items: center;
                border-radius: 10px;

                .indicator-arrow {
                  padding-left: 0.5vw;
                }
              }
            }
          }


        }

      }
    }


    .respond_event_button {
      background: #FBEC66;
      border-radius: 20px;
      text-transform: uppercase;
      height: 4vh;
      width: 14vw;
      margin-top: 2vh;
      color: #0D54BD;
      align-self: flex-start;
      font-size: 1.1vw;
      font-weight: 500;
      cursor: pointer;
    }
    


  }

  .event_loader {
    @include mixins.display-flex-center;
    background-image: url('../../../assets/images/event_loader_bg.svg'), url('../../../assets/images/event_loader_bg2.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: center center, center center;
    width: 100vw;

    .loading_text {
      font-family: Roboto;
      font-weight: 400;
      font-size: 1.4vw;
      color: #0D54BD;

    }

  }

  .response_design {
    align-self: center;
    width: 23.5vw;
  }

  .close {
    width: 3vw;
    height: 3vw;
    position: relative;
    top: 2vh;
    right: 0.5vw;
    border-radius: 50%;
    cursor: pointer;
  }

  .close:hover {
    box-shadow: 0px 0 5px rgba(0, 0, 0, 0.5);
  }

  .footer {
    z-index: -1;
    width: 100vw;
    position: fixed;
    bottom: -3vh;
    pointer-events: none;
  }
}