@use 'src/styles/_colors.scss';


.dropdown{
    border: none;
    border-radius: 3vh !important;
    width: 90%;
    background: #E2EEFF;
    // padding-right: 1.5vw;
    color: colors.get-color("black");
    // MuiSelect-select MuiSelect-outlined MuiOutlinedInput-input MuiInputBase-input
    .MuiSelect-select{
        padding: 0.7vmin;
    }
    svg{
        color: colors.get-color("blue");
    }
    .MuiOutlinedInput-notchedOutline{
        padding: 0;
        legend span{
            padding-left: 0;
        }
    }
}
.ch-drop{
    width: 3.5vh;
    height: 3.5vh;
    padding-right: 0.3vw;
}
.MuiPaper-root{
    border-radius: 5% !important;
}
.MuiList-root{    
    background: #F3F8FF;
    padding: 0 !important;
}
.menu-item{
    padding: 0.5vh 0.5vw;
}
.menu-item:hover{
    background-color: #c8dff6 !important;
}
.MuiSelect-select{
    display: inherit !important;
}