@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.clock {
  font-family: Roboto Mono;
  color: white;
  font-weight: bold;
  font-size: 1.5vw;
  z-index: 10;
  // line-height: 24px;
  // text-align: center;
  // letter-spacing: -1px;
}