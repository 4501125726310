@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';


.event_res {
    @include mixins.display-flex;
    margin-top: auto;

    .card {
      @include mixins.display-flex-column;
      padding-right: 1vw;

      .char {
        width: 5vw;
        height: 5vw;
        align-self: center;
        padding-bottom: 1vh;
      }

      .impact {
        @include mixins.display-flex-column;
        background: #FFFFFF;
        width: 9vw;
        border-radius: 6px;
        box-shadow: 0px 2px 4px #E7EFFE;
        align-self: stretch;

        .first_name {
          padding: 2px 0.5vw 1vh 0.5vw;
          font-family: Roboto;
          color: #0D54BD;
          text-align: center;
          font-size: 1vw;
          font-weight: normal;
        }

        .impact_body {
          @include mixins.display-flex-column;
          padding-left: 1vw;
          padding-right: 1vw;

          .each {
            width: 100%;
            @include mixins.display-flex-space-between;
            align-items: center;
            padding-bottom: 1vh;

            .label {
              font-family: Roboto;
              font-size: 0.95vw;
              color: #0D54BD;
            }

            .ty {
              color: #FC8484;
              font-weight: bold;
              font-size: 0.95vw;

              .percentage {
                font-size: 0.7vw;
              }
            }

            .impact-indicator {
              @include mixins.display-flex-center;
              width: 3.5vw;
              height: 2.7vh;
              align-items: center;
              border-radius: 10px;

              .indicator-arrow {
                padding-left: 0.5vw;
              }
            }
          }
        }


      }

    }
  }