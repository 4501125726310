@use 'src/styles/_colors.scss';

@mixin theader{
    margin-top: 10vh;
    margin-bottom: 2vh;
    width: 60vw;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
    border-radius: 10px;
    height: 10vh;
    display: flex;
}
@mixin timage {
    height: 20vh;
    margin-left: 5vw;
    margin-top: -10vh;
}
@mixin tqoute {
    margin-top: 2vh;
    margin-left: 2vw;
    width: 4vw;
    height: 3vh;
}
@mixin ceo-qoute {
    margin-top: 2vh;
    margin-left: 1vw;
    margin-right: 1vw;
    margin-block: auto;
    color: #1E2734;
    font-family: Roboto;
    font-size: colors.get-font("m");
    display: flex;
    flex-direction: column;
    justify-content: space-evenly
}
@mixin tbody{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 60vw;
    height: 43vh;
    padding: 3vh 0 0 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
    border-radius: 10px;
}