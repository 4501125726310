@use 'src/styles/_colors.scss';
@use 'src/styles/_mixins.scss';

.day{
    font-weight: 50;
}
.report{
    padding-bottom: 1vmin;
    .tbody{
        width: 77vw;
        height: 55vh;
    }
    .bar-container .bar-content .bar-label{
        color: colors.get-color("blue");
        font-size: 1.4vh;
    }
    .progress-container .title-meter{
        color: colors.get-color("blue");
        padding-bottom: 2vh;
        font-size: 2vh;
    }
    .bar-style {
        border: 0.6px solid colors.get-color("blue");;
      }
}

.pbody{
    .textColorPrimary{
        color: colors.get-color("blue");
        font-weight: 300;
        font-size: 1.1vmax;
        max-width: 386px;
    }
    .Mui-selected{
        font-weight: 700;
    }
}


.performance{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4vh;
    height: 53vh;

    .trust-meter{
        margin-top: 4%;
        margin-left: 25%;
        margin-right: 15%;
        width: 50%;
        .progress-bar-container{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
    }

    .target-analysis-report {
        @include mixins.display-flex-center;
        margin: 1vh 0 1vh 0;

        .target-icon {
            width: 1.5vw;
            height: 1.5vw;
            margin-right: 0.2vw;
        }

        .target-comment {
            font-family: Roboto;
            font-size: 1.1vw;
            line-height: 1.5vw;
            color: #1E2734;
            font-weight: 400;
        }
    }
}

.leaderboard{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4vh;
}

.pm{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2vh;
    width: 97%;
    .container{
        width: 100%;
        height: 52vmin;
        background: #EAEDF2;
        display: flex;  
        justify-content: space-around;
        padding: 1.5vmin 0 1.5vmin 0;
        .powermap{
            background: #fff;
            width: 50%;
            height: 100%;
            border-radius: 2vh;
            pointer-events: none;
        }
        .pm-analysis{
            width: 40%;
            background: #fff;
            border-radius: 2vh;
            display: flex;
            flex-direction: column;
            .style{
                display: flex;
                flex-direction: column;
                margin-left: 5vmin;
                margin-top: 3vmax;
                // margin-right: 3vmax;
                p{
                    margin-left: 9vw;
                    font-family: 'Roboto';
                    font-size: 2.4vw;
                    font-weight: 500;
                    color: colors.get-color("blue");
                }
                span{
                    font-family: 'Roboto';
                    line-height: 2vw;
                    margin-right: 2vmax;
                    font-size: 1.2vmax;
                }
            }
            .connections{
                margin-bottom: 1vmin;
                margin-top: auto;
                margin-left: 11vw;
                color: colors.get-color("blue");
                font-size: 1.6vw;
                
            }
            .conn-grid{
                display: grid;
                grid-template-columns: auto auto;
                grid-gap: 1vmin;
                margin: 1vmin;
                div{
                    background: #F5F8FA;
                    border-radius: 0.5vw;
                    padding: 1vmin;
                    text-align: center;
                    padding-bottom: 1vmin;
                    height: 10vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    p{
                        color: #000000;
                    }
                }
            }
        }
    } 
}

.analysis{
    background: white;
    padding: 2vh 1vw 2vh  1vw;
    border-radius: 1vw;
    display: flex;
    justify-content: center;
    .ct-point{
        fill: #57ADFE;
        stroke:#57ADFE;
    }
    .ct-area{
        fill: #57ADFE;
    }
    .ct-line{
        stroke: #57ADFE;
    }
    .ct-octave{
        width: 90%;
        height: 90%;
    }
    .xlabel{
        position: absolute;
        top: 100%;
    }
    .ylabel{
        transform: rotate(-90deg);
        top: 65%;
        left: 4%;
        position: absolute;
    }
}