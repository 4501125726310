@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.z-index-1 {
  z-index: -1
}

.z-index-3 {
  z-index: 3
}

.z-index-4 {
  z-index: 4
}


.powermap-gradient {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #FFFFFF 50.52%, rgba(255, 255, 255, 0) 100%) !important;
}

.group-action-gradient {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #FFFFFF 50.52%, rgba(255, 255, 255, 0) 100%);
}

.pointer-events-all {
  pointer-events: all;
}

@keyframes animateBorderWidth {
  0% {border: 2px solid #054B7D;}
  50% {border: 4px solid #054B7D;}
  100% {border: 2px solid #054B7D;}
} 

@-webkit-keyframes animateBorderWidth {
  0% {border: 2px solid #054B7D;}
  50% {border: 4px solid #054B7D;}
  100% {border: 2px solid #054B7D;}
}

.animateBorderWidth {
  -webkit-animation-name: animateBorderWidth;
  animation-name: animateBorderWidth;
}

.animate-box {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


/* Step 1 */

.box-trust-meter {
  z-index: 5;
  position: absolute;
  top: 0.5vh;
  left: 15vw;
  width: 30vw;
  height: 15.5vh;
  border: 2px solid #054B7D;
  box-sizing: border-box;
  transition: border-width 1s linear;
}

.box-timer {
  z-index: 5;
  position: absolute;
  top: 0.5vh;
  left: 45.7vw;
  width: 9.5vw;
  height: 15.5vh;
  border: 2px solid #054B7D;
  box-sizing: border-box;
}

.box-others {
  z-index: 5;
  position: absolute;
  top: 0.5vh;
  left: 56vw;
  width: 32.5vw;
  height: 15.5vh;
  border: 2px solid #054B7D;
  box-sizing: border-box;
}

.s1-meter {
  width: 22vw;
  height: fit-content;
  left: 12vw;
  position: absolute;
  top: 20vh;
  z-index: 3;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
  border-radius: 10px;

  .s1-meter-content {
    margin: 3vh 0.5vw 3vh 1.5vw;
    width: auto;
    @include mixins.display-flex-column;

    .title {
      height: fit-content;
      font-family: Roboto;
      font-size: 1.1vw;
      font-weight: 500;
      color: #0D54BD;
      padding-bottom: 2vh;
    }

    .description {
      width: 90%;
      height: 70%;
      font-family: Roboto;
      font-style: normal;
      font-size: 1vw;
      color: #1E2734;
    }
  }
}

.s1-connector1{
  position: absolute;
  top: 15vh;
  left: 20vw;
  z-index: 4;
}

.s1-connector2{
  position: absolute;
  top: 15vh;
  left: 48vw;
  z-index: 4;
}

.s1-connector3{
  position: absolute;
  top: 15vh;
  left: 65vw;
  z-index: 4;
  width: 20vw;
}


.s1-timer {
  width: 24vw;
  height: fit-content;
  left: 38vw;
  position: absolute;
  top: 20vh;
  z-index: 3;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
  border-radius: 10px;

  .s1-timer-content {
    margin: 3vh 0.5vw 3vh 1.5vw;
    width: auto;
    @include mixins.display-flex-column;

    .title {
      height: fit-content;
      font-family: Roboto;
      font-size: 1.1vw;
      font-weight: 500;
      color: #0D54BD;
      padding-bottom: 2vh;
    }

    .description {
      width: 90%;
      height: 70%;
      font-family: Roboto;
      font-style: normal;
      font-size: 1vw;
      color: #1E2734;
    }
  }
}

.s1-metric {
  width: 20vw;
  height: fit-content;
  left: 70vw;
  position: absolute;
  top: 20vh;
  z-index: 3;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
  border-radius: 10px;

  .s1-metric-content {
    margin: 3vh 0.5vw 3vh 1.5vw;
    width: auto;
    @include mixins.display-flex-column;

    .title {
      height: fit-content;
      font-family: Roboto;
      font-size: 1.1vw;
      font-weight: 500;
      color: #0D54BD;
      padding-bottom: 2vh;
    }

    .description {
      width: 90%;
      height: 70%;
      font-family: Roboto;
      font-style: normal;
      font-size: 1vw;
      color: #1E2734;
    }
  }

  .s1-navigation-buttons {
    @include mixins.display-flex;
    position: absolute;
    bottom: -5vh;
    right: 0vw;
    z-index: 4;
  
    .s1-next {
      background: #FBEC66;
      width: 10vw;
      height: 4vh;
      border-radius: 40px;
      border: none;
      font-size: 1.1vw;
      font-family: Roboto;
      font-weight: 500;
      color: #0D54BD;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  
}




.s2-stakeholder {
  width: 20vw;
  height: fit-content;
  left: 78vw;
  position: absolute;
  top: 24vh;
  z-index: 3;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
  border-radius: 10px;

  .s2-stakeholder-content {
    margin: 2vh 0.5vw 2vh 1.2vw;
    width: fit-content;
    height: fit-content;
    @include mixins.display-flex-column;

    .title {
      height: fit-content;
      font-family: Roboto;
      font-size: 1.1vw;
      font-weight: 500;
      color: #0D54BD;
      padding-bottom: 2vh;
    }

    .description {
      width: 90%;
      height: 70%;
      font-family: Roboto;
      font-style: normal;
      font-size: 1vw;
      color: #1E2734;
    }
  }
}
.s2-connector1{
  position: absolute;
  top: 19vh;
  left: 33vw;
  z-index: 4;
  width: 60vw;
}
.s2-connector2{
  position: absolute;
  top: 33vh;
  left: 70vw;
  z-index: 4;
  width: 20vw;
}

.s2-strust {
  width: 18vw;
  height: fit-content;
  position: absolute;
  z-index: 3;
  top: 40vh;
  left: 79vw;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
  border-radius: 10px;

  .s2-strust-content {
    margin: 3vh 0.5vw 2vh 1.2vw;
    width: auto;
    height: fit-content;
    @include mixins.display-flex-column;

    .title {
      height: fit-content;
      font-family: Roboto;
      font-size: 1.1vw;
      font-weight: 500;
      color: #0D54BD;
      padding-bottom: 2vh;
    }

    .description {
      @include mixins.display-flex-column;
      width: 90%;
      height: 70%;
      font-family: Roboto;
      font-style: normal;
      font-size: 1vw;
      color: #1E2734;

      .d-text {
        padding-bottom: 0.5vh;
      }
      .progress-bar{
        display: flex;
        flex-direction: row;
        font-size: 0.73vw;
        font-weight: bold;
        color: #1E2734;
        align-items: center; 
        
        .trustLabel {
          font-family: Roboto;
        }

        .client-bar {
          @include mixins.display-flex-center;
          overflow: hidden;
          border-radius: 0.5vh;
          margin-left: 0.2vw;
          height: 1vh;

          .progress-bar-style{
            width: 11vw;
            overflow: visible;
            height: 0.5vh;
            border-radius: 0.5vh;
          }
        } 
        
      }
    
    }
  }

  .s2-navigation-buttons {
    @include mixins.display-flex;
    position: absolute;
    
    z-index: 4;
    bottom: -5vh;
    right: 0vw;
  
    .s2-prev {
      background: transparent;
      border: none;
      margin-right: 1.5vw;
      font-weight: 400;
      font-family: Roboto;
      font-size: 1.1vw;
      width: 6vw;
      color: #FBEC66;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      cursor: pointer;
    }
  
    .s2-next {
      background: #FBEC66;
      width: 8vw;
      height: 4vh;
      border-radius: 40px;
      border: none;
      font-size: 1vw;
      font-family: Roboto;
      font-weight: 500;
      color: #0D54BD;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  

}


/* Step 3 */
.s3-connector1{
  z-index: 5;
  position: absolute;
  top: 26vh;
  left: 86vw;
  width: 12vw;
  height: 9vh;
}
.s3-connector2{
  position: absolute;
  top: 77.5vh;
  left: 65.7vw;
  z-index: 4;
  width: 15vw;
  height: 8vh;
  transform: rotate(-90deg);
}

.s3-powermap {
  width: 18vw;
  height: fit-content;
  position: absolute;
  z-index: 3;
  top: 31vh;
  left: 78vw;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
  border-radius: 10px;

  .s3-powermap-content {
    margin: 4.1vh 0.5vw 3vh 1.2vw;
    width: auto;
    height: fit-content;
    @include mixins.display-flex-column;

    .title {
      height: fit-content;
      font-family: Roboto;
      font-size: 1.1vw;
      line-height: 1.5vw;
      font-weight: 500;
      color: #0D54BD;
      padding-bottom: 2vh;
    }

    .description {
      @include mixins.display-flex-column;
      width: 90%;
      height: 70%;
      font-family: Roboto;
      font-style: normal;
      font-size: 1.6vh;
      color: #1E2734;

      .d-text {
        padding-bottom: 0.5vh;
      }
    
    }
  }

}

.s3-your-team {
  width: 17.5vw;
  height: fit-content;
  position: absolute;
  z-index: 3;
  top: 62.5vh;
  left: 74vw;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
  border-radius: 10px;

  .s3-your-team-content {
    margin: 4.1vh 0.5vw 3vh 1.2vw;
    width: auto;
    height: fit-content;
    @include mixins.display-flex-column;

    .title {
      height: fit-content;
      font-family: Roboto;
      font-size: 1.1vw;
      line-height: 1.5vw;
      font-weight: 500;
      color: #0D54BD;
      padding-bottom: 2vh;
    }

    .description {
      @include mixins.display-flex-column;
      width: 90%;
      height: 70%;
      font-family: Roboto;
      font-style: normal;
      font-size: 1.6vh;
      color: #1E2734;
    }
  }

  .s3-navigation-buttons {
    @include mixins.display-flex;
    position: absolute;
    
    z-index: 4;
    bottom: -5vh;
    right: 0vw;
  
    .s3-prev {
      background: transparent;
      border: none;
      margin-right: 1.5vw;
      font-weight: 400;
      font-family: Roboto;
      font-size: 1.1vw;
      width: 6vw;
      color: #FBEC66;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      cursor: pointer;
    }
  
    .s3-next {
      background: #FBEC66;
      width: 9vw;
      height: 4vh;
      border-radius: 40px;
      border: none;
      font-size: 1vw;
      font-family: Roboto;
      font-weight: 500;
      color: #0D54BD;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

}




/* Step 4 */
.s4-connector1{
  z-index: 5;
  position: absolute;
  top: 47.7vh;
  left: 6vw;
  width: 15vw;
  height: 10vh;
}

.s4-group-action {
  width: 22vw;
  height: fit-content;
  position: absolute;
  z-index: 4;
  top: 52vh;
  left: 9vw;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
  border-radius: 10px;

  .s4-group-action-content {
    margin: 4.1vh 0.5vw 4vh 1.2vw;
    width: auto;
    height: fit-content;
    @include mixins.display-flex-column;

    .title {
      height: fit-content;
      font-family: Roboto;
      font-size: 1.1vw;
      line-height: 1.5vw;
      font-weight: 500;
      color: #0D54BD;
      padding-bottom: 2vh;
    }

    .description {
      @include mixins.display-flex-column;
      width: 90%;
      height: 70%;
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      font-size: 1vw;
      color: #1E2734;
    }
  }

  .s4-navigation-buttons {
    @include mixins.display-flex;
    position: absolute;
    z-index: 4;
    bottom: -5vh;
    right: 0;
  
    .s4-prev {
      background: transparent;
      border: none;
      margin-right: 1.5vw;
      font-weight: 400;
      font-family: Roboto;
      font-size: 1.1vw;
      width: 6vw;
      color: #FBEC66;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      cursor: pointer;
    }
  
    .s4-next {
      background: #FBEC66;
      width: 9vw;
      height: 4vh;
      border-radius: 40px;
      border: none;
      font-size: 1vw;
      font-family: Roboto;
      font-weight: 500;
      color: #0D54BD;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  
  

}



/* Step 5 */

.s5-connector1 {
  z-index: 5;
  position: absolute;
  top: 47.5vh;
  left: 76vw;
  width: 15vw;
  height: 10vh;
}

.client-container-mask {
  position: absolute;
  top: 20vh;
  left: 24vw;
  width: 54vw;
  height: 62vh;
  background: rgba(196, 196, 196, 0.8);
  mix-blend-mode: hard-light;
  border-radius: 10px;
  z-index: 2;
}

.s5-stakeholder {
  width: 19vw;
  height: fit-content;
  left: 79vw;
  position: absolute;
  top: 53vh;
  z-index: 3;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
  border-radius: 10px;

  .s5-stakeholder-content {
    margin: 3vh 0.5vw 3vh 1.5vw;
    width: auto;
    height: fit-content;
    @include mixins.display-flex-column;

    .title {
      height: fit-content;
      font-family: Roboto;
      font-size: 1.1vw;
      font-weight: 500;
      color: #0D54BD;
      padding-bottom: 1vh;
    }

  }

  .s5-navigation-buttons {
    @include mixins.display-flex;
    position: absolute;
    font-family: Roboto;
    z-index: 4;
    bottom: -4vh;
    right: 0;
  
    .s5-prev {
      background: transparent;
      border: none;
      font-size: 1.1vw;
      font-weight: 400;
      font-family: Roboto;
      width: 8vw;
      color: #FBEC66;;
      cursor: pointer;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}


/* Step 6 */

.s6-connector1 {
  z-index: 5;
  position: absolute;
  top: 33vh;
  left: -15vw;
  width: 15vw;
  height: 10vh;
}

.s6-unlocked-tip {
  width: 19vw;
  height: fit-content;
  left: -20vw;
  position: absolute;
  top: 38vh;
  z-index: 4;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
  border-radius: 10px;
  border: 1px solid #0D54BD;

  .s6-tip-content {
    margin: 3vh 0.5vw 3vh 1.5vw;
    width: auto;
    height: fit-content;
    @include mixins.display-flex-column;

    .title {
      height: fit-content;
      font-family: Roboto;
      font-size: 1.1vw;
      font-weight: 500;
      color: #0D54BD;
      padding-bottom: 1vh;
    }
  }
}

/* Step 7 */

.s7-group-action {
  width: 15.5vw;
  height: fit-content;
  left: 5vw;
  position: absolute;
  top: 1vh;
  background: #FFFFFF;
  box-shadow: 0px 4px 15px rgba(31, 72, 174, 0.25);
  border-radius: 10px;

  
  .s7-content {
    margin: 3vh 1vw 3vh 1.5vw;
    width: auto;
    height: fit-content;
    @include mixins.display-flex-column;

    .title {
      height: fit-content;
      font-family: Roboto;
      font-size: 1.1vw;
      font-weight: 500;
      color: #0D54BD;
      padding-bottom: 1vh;
    }
  }
}

.s7-group-action::after {
  content: "";
  position: absolute;
  top: 15%;
  right: 100%;
  border-width: 0.7vmax;
  border-style: solid;
  border-color: transparent white transparent transparent;
}

/*Group Action Unlocked Tip */
.s8-connector1{
  z-index: 2;
  position: absolute;
  top: 35vh;
  left: 3.8vw;
  width: 15vw;
  height: 10vh;
  pointer-events: none;
}

.s8-group-action-unlocked {
  width: 15vw;
  height: fit-content;
  left: 7vw;
  position: absolute;
  top: 40vh;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
  border-radius: 10px;

  
  .s8-content {
    margin: 3vh 1vw 3vh 1.5vw;
    width: auto;
    height: fit-content;
    @include mixins.display-flex-column;

    .title {
      height: fit-content;
      font-family: Roboto;
      font-size: 1.1vw;
      font-weight: 500;
      color: #0D54BD;
      padding-bottom: 1vh;
    }
  }
}