@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.cbody{
    @include mixins.display-flex-space-between;
    width: 70vw;
    padding-top: 2vh;
    padding-right: 3vw;
    font-family: Roboto;
    height: 57vh;
    .tbody{
        width: 34vw;
        padding-bottom: 5vh;
        height: auto;
        .bbody{
            margin-right: 2vw;
            margin-left: 2vw;
        }
    }
    .trepsbody {
        background-color: colors.get-color("blue");
        color: #fff;
        padding: 0;
        // height: auto;
    }
}

.bt-container{
    height: 55vh;
    width: 70vw;
    font-family: Roboto;
    padding-left: 3vw;
    .theader{
        margin-top: 4vh;
        margin-left: 3vw;
        height: 7vh;
        .thtext{
            color: colors.get-color("black");
            font-size: 1vmax;
            padding-left: 2vw;
            line-height: 2.2vh;
        }
    }
    .tbody{
        padding: 2vh 0 2vh 0;
        margin-left: 3vw;
        .bbody{
            margin-right: 2vw;
            margin-left: 2vw;
        }
        ul{
            color: colors.get-color("blue");
            margin-top: 2vh;
            margin-left: 4vw;
            font-size: 1.6vw;
            line-height: 3vh;
            .li-head{
                font-size: 1vmax;
            }
            .bdesc{
                color: colors.get-color("black");
                font-size: 1vmax;
            }
        }
    }
}
