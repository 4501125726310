@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.progress-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;

  .title-meter {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 1vw;
    line-height: 100%;
    letter-spacing: 0.12em;
    color: #FFFFFF;
    font-weight: 500;
    padding-top: 2px;

    .info-icon {
      width: 1vw;
      opacity: 0.5;
      padding-left: 2px;
    }
  }
  .legends {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    margin: 0%;
    height: 8vh !important;
    
    .circular-legend {
      display: flex;
      justify-content: center;
      width: 1.5vw;
      height: 1.5vw;
      font-weight:600;
      font-size: 16px;
      background: #FFFFFF;
      opacity: 0.1;
      // border: 50% solid #FFFFFF;
      box-sizing: border-box;
      border-radius:50%
    }
  }
  .progress-bar-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
      // padding-right: 2%;
    }
    
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  }