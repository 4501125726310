@use 'src/styles/_colors.scss';
@use 'src/styles/_mixins.scss';
@use './_mixin';

.intro-tabs{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: -5vh;

    .tabHead{
        // text-transform: none;
        color: colors.get-color("blue")!important;
        font-size: colors.get-font("m");
        font-weight: 300;
    }
    .MuiTab-textColorPrimary.Mui-selected{
        font-weight: 500;
    }
}
.indicator{
    background:   linear-gradient(270deg, rgba(5, 75, 125, 0) 0%, colors.get-color("blue") 51.43%, rgba(5, 75, 125, 0) 100.76%);
    border-bottom-width: 0px;  
    border-top-width: 0px; 
    border-right-width: 0px; 
    border-left-width: 0px;
    border-image-slice: 1;
    font-weight: 500;
    background-color: initial !important;
    width: 1vw;
}

// Introduction
.theader{
    @include mixin.theader;
  .timage{
    @include mixin.timage;
  }
  .tqoute{
    @include mixin.tqoute;
  }
  .ceo-qoute{
      @include mixin.ceo-qoute;
  }
}
.tbody{
  @include mixin.tbody;
  // z-index: 2;
  min-height: 43vh;
  height: auto;
  .bhead{
    color: colors.get-color("blue");
    font-family: Roboto;
    font-size: 1.2vmax;
    margin-left: 6vw;
  }
  .bbody{
    color: colors.get-color("black");
    font-size: colors.get-font("sm");
    margin-right: 4vw;
    margin-top: 2vh;
    // margin-bottom: 1vh;
    line-height: 1.4vmax;
  }
  ol {
    list-style-position: inside;
    padding-top: 2vh;
    color: colors.get-color("blue");
    li{
      font-size: 1vmax;
      padding-bottom: 3vh;
      line-height: 2vmax;
      list-style: none;
      background: url('../../../assets/images/intro/obj_bullet.svg') no-repeat left top; 
      /* <-- change `left` & `top` too for extra control */
      padding: 0 2vw 3vh 3vw;
    }
  }
}
.tbuttons{
  // margin-left: 10vw;
  display: flex;
  justify-content: center;
  padding-bottom: 2vh;
  text-transform: uppercase;
  padding-top: 1vh;
  margin-top: auto;

  .back{
    border: none;
    background: none;
    color: colors.get-color("blue");
    cursor: pointer;
    // margin-right: auto;
    font-size: colors.get-font("m");
    font-family: Roboto;
    border-radius: 2vh;
    border: 0.1vmax solid colors.get-color("yellow");
    box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
    padding: 5px 40px;
  }
  .next{
    color: colors.get-color("blue");
    background-color: colors.get-color("yellow");
    box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
    border-radius: 2vh;
    border: none;
    padding: 8px 40px;
    font-size: colors.get-font("m");
    font-weight: 500;
    font-family: Roboto;
    cursor: pointer;
    margin-left: 2vw;
  }
}

// Meet your team
.mty-header{
  @include mixin.theader;
  height: fit-content;
  background: none;
  box-shadow: none;
  margin-top: 3vh;
  width: 80vw;
  height: 60vh;
  display: flex;
  justify-content: center;
  // flex-direction: row;
}
.mty-body{
  @include mixin.tbody;
  height: initial;
  padding-top:0;
  z-index: 2;
}
.mty-actor{
  @include mixin.tbody;
  margin-right: 3vw;
  height: initial;
  padding: 3vh 2vw 2vh 2vw;
  
  .team-header {
    display: grid;
    grid-column: 2;
    grid-template-columns: auto 10vw;
    height: 40%;

    .actor-tabs {
      grid-column: 1;
      height: 7vmax;
      margin-bottom: auto;

      .intro-team-member-title {
        font-family: Roboto;
        font-weight: 400;
        font-size: 1vw;
        padding-top: 0.5vh;
        color: #0D54BD;
      }

      .intro-yt {
        width: 19vw;
        justify-content: space-between;
        margin-left: 0;
      }
    }
  
    .actor-name{
      grid-column: 1;
      font-family: Roboto;
      font-size: colors.get-font("m");
      color: colors.get-color("blue");
      font-weight: 500;
      margin-bottom: 1vmax;
    }
    .divider{
      grid-column: 1/3;
      background: linear-gradient(270deg, rgba(5, 75, 125, 0) 20%, #0D54BD 71.43%, rgba(5, 75, 125, 0) 150.76%);
      border-image-slice: 1;
      height: 0.2vh;
      margin-bottom: 1vmax;
    }

    .illustration-team {
      width: 8.6vmax;
      grid-row: 1/3;
      grid-column: 2;
      margin-left: auto;
    }
  }

  .actor-desc{
    font-family: Roboto;
    font-size: colors.get-font("sm");
    font-weight: 400;
    color: colors.get-color("black");
    line-height: 1.4vmax;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 60%;
    margin-top: 1vmin;
  }
}
.mty-buttons{
  @extend .tbuttons;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-left: 0;
  padding: 1.5vh 0 1.5vh 5vw;
  // min-height: fit-content; // Edge doesnt support hence 100%
  min-height: 12%; 
  // height: unset;
  width: 75vw;
  z-index: 2;
  position: relative;
  .startSim{
    margin-left: 2vw;
    margin-right: 2vw;
    cursor: pointer;
  }
  .back{
    margin-right: auto;
  }
  
  .tryDemo{
    cursor: pointer;
  }

  .disable-button {
    cursor: not-allowed !important;
    opacity: 0.4;
  }

  .next{
    padding: 1.5vh 4vw;
    cursor: pointer;
  }
}
.actor-tabs .your-team-container .team-member .intro-selected{
  width: 4.4vw;
  border: 2px solid #0D54BD;
}

.member-image:not(.intro-selected) + .intro-team-member-title {
  font-weight: 500;
  color: #000000 !important;
  
}

.your-team-container .team-member .intro-team-image{
  width: 4vw;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

// powermap

.powermap-container{
  height: 100%;
  border-radius: 10px;
  .reps-container{
    height: 60vh;
  }
}
.pm-image{
  border:2px solid #0D54BD;
}

.powermap-tip {
  width: 30vw;
  height: 8vh;
  position: absolute;
  bottom: 12vh;
  left: 3vw;
}