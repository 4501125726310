@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.client-description {
  z-index: 8;
  min-width: 55vw;
  width: fit-content;
  // display: table;  /*add this line for edge*/
  max-height: 66vh;
  min-height: 78vh;
  height: fit-content;
  top: 1vh;
  bottom: 0;
  margin: auto; //8vmax auto auto 20vmax;
  position: absolute;
  left: 0;
  right: 0;
  background: #FFFFFF;;
  border-radius: 10px;
  font-family: Roboto;

  .description-header {
    @include mixins.display-flex-start;
    width: 100%;
    height: 12vh;
    background: linear-gradient(92.13deg, #2E78BD 0.83%, #054B7D 100%), linear-gradient(180deg, #EDEDED 2.44%, #FFFFFF 100%);
    border-radius: 10px 10px 0 0;

    .header-profile {
      @include mixins.display-flex;
      align-items: center;
      padding-left: 2vw;
      width: 20vw;
      .profile-details {
        @include mixins.display-flex-column;
        padding-left: 2%;

        .client-name {
          font-weight: 500;
          font-size: 0.9vw;
          color: #EEEEEE;
          line-height: 1.1vw;
          padding: 1vh 0 0 0;
        }
        .client-designation {
          font-weight: 300;
          font-size: 0.8vw;
          color: #EEEEEE;
        }
      }
    }

    .client-progress {
      @include mixins.display-flex-column;
      width: 100%;

      .progress-bar-title {
        font-size: 0.8vw;
        font-weight: 300;
        color: #EEEEEE; 
        line-height: 1vw;
      }

      .description-progress-meter{
        height: 6vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        
          .progress-bar{
            display: flex;
            flex-direction: row;
            font-size: 0.73vw;
            font-weight: bold;
            color: #FFFFFF;
            align-items: center;    
            
            .client-bar {
              @include mixins.display-flex-center;
              overflow: hidden;
              border-radius: 0.5vh;
              margin-left: 0.2vw;
              margin-right: 0.2vw;
              height: 1vh;

              .progress-bar-style{
                width: 11vw;
                margin-left: 0.2vw;
                overflow: visible;
                height: 0.5vh;
                border-radius: 0.5vh;
              }
            }
          }
        .trustLabel {
          font-size: 0.8vw;
          font-weight: normal;
        }
      }
    }
    
    .close-button {
      margin-left: auto;
      margin-top: -6vh;
      margin-right: 0.5vw;
      border-radius: 50%;
      cursor: pointer;
      width: 2vw;
      height: fit-content;
    }

    .close-button:hover {
      box-shadow: 0px 0 5px rgba(0, 0, 0, 0.5);
    }
  }

  .description-content {
    @include mixins.display-flex;
    min-height: 66vh;
    height: fit-content;
    width: fit-content;
    border-radius: 0 10px;

    .client-info-container {
      // background: radial-gradient(circle at top, #FFF 0%, #FFF 80%, #5DF4 80%, #DDD 100%);
      @include mixins.display-flex-column;
      @include mixins.border-vertical-blue;
      border-right: 1px solid;
      border-left-width: 0px;
      border-top-width: 0px;
      border-bottom-width: 0px;
      width: 40vw;
      min-height: 66vh;
      height: fit-content;
      background-image: url('../../../../assets/images/client_description_footer.svg');
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: 100%;

      .client-info-footer {
        width: auto;
        position: relative;
        bottom: 0;
        margin-top: auto;
        z-index: -1;

      }
    }

    .actions-container {
      @include mixins.display-flex-center;
      width: fit-content;
      display: table;  /*add this line for edge*/
      padding-left: 1vw;
      position: relative;
    }
  }
}
