@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.tooltip-container {
  @include mixins.display-flex-center;
  font-family: Roboto;
  width: 100%;
  min-height: 100%;
  height: fit-content;
  background: #E5ECF7;
  backdrop-filter: blur(13px);
  border-radius: 40px;

  .tooltip-text {
    width: 85%;
    font-size: 0.85vw;
    font-style: italic;
    font-weight: 400;
    color: #1E2734;
    line-height: 1vw;
  }
}