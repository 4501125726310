@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.pointer-events-none {
  pointer-events: none;
}

.opacity-5 {
  opacity: 0.5;
}

.z-index-2 {
  z-index: 2;
}

.group-actions-container {
  display: flex;
  height: fit-content;
  align-self: center;
  align-items: center;
  width: 24.1vw;

  .group-actions {
    @include mixins.display-flex-column;
    @include mixins.display-flex-center;
    @include mixins.border-vertical-blue;
    width: 7vw;
    height: fit-content;
    font-family: Roboto;
    font-size: 1.7vh;
    font-weight: 400;
    text-align: center;
    color: #0D54BD;
    border-top-width: 0px;
    border-left-width: 0px;
    border-bottom-width: 0px;
    border-right: 1.5px solid;
    margin-top: 4vh;
    padding-bottom: 1.5vh;
  
    .title {
      @include mixins.border-horizontal-blue;
      width: 5vw;
      border-top-width: 0px;
      border-left-width: 0px;
      border-right-width: 0px;
      border-bottom: 1px solid;
      background-clip: content-box;
      text-transform: uppercase;
      font-size: 1vw;
      padding-bottom: 2vh;
    }

    .actions {
      @include mixins.display-flex-column;
      @include mixins.display-flex-space-between;
      width: auto;
      height: fit-content;
      padding-top: 2vh;
      align-self: stretch;
      align-items: center;
    }
  }

  .locked-actions {
    @include mixins.display-flex-center;
    position: relative;
    background: linear-gradient(92.13deg, #F1F5F9 0.83%, #FFFFFF 100%);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
    width: 4vw;
    height: 4vw;
    border-radius: 50%;
    transform: translateX(-50%);

    .lock-icon {
      width: 1.3vw;
    }
  }
  .group-action-description {
    @include mixins.display-flex;
    background: linear-gradient(341.24deg, #EFF5FF 12.68%, #FFFFFF 87.32%);
    filter: drop-shadow(0px 4px 14px rgba(1, 44, 154, 0.05));
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.05);
    border-radius: 5%;
    width: 17vw;
    min-height: 55vh;
    height: fit-content;
    margin-top: 4vh;
  }
}


