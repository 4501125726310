@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.pointer-events-none {
  pointer-events: none;
}

.opacity-4 {
  opacity: 0.4;
}

.demo-over-container {
  @include mixins.display-flex-column;
  position: absolute;
  left: 36.5vw;
  top: 18vh;
  width: 27vw;
  padding: 3vh 1vw 4vh 1vw;
  z-index: 9;
  background: #FFFFFF;
  border-radius: 10px;
  height: fit-content;

  .exit-confirmation-buttons {
    padding-top: 2vh;
    width: 100%;
    @include mixins.display-flex-center;

    .btn-cancel {
      background: transparent;
      margin-right: 1.5vw;
      font-weight: 500;
      font-family: Roboto;
      font-size: 1.1vw;
      width: 8vw;
      height: 4vh;
      border-radius: 40px;
      border: 1.5px solid #FBEC66;
      box-sizing: border-box;
      color: #0D54BD;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      cursor: pointer;
    }

    .btn-exit {
      background: #FBEC66;
      width: 8vw;
      height: 4vh;
      border-radius: 40px;
      border: none;
      font-size: 1vw;
      font-family: Roboto;
      font-weight: 500;
      color: #0D54BD;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      cursor: pointer;
    }

  }
}

.t-footer {
  z-index: -1;
  width: 27vw;
  position: absolute;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.timer-over-container {
  @include mixins.display-flex-column;
  position: absolute;
  left: 36.5vw;
  top: 12vh;
  width: 27vw;
  padding: 3vh 1vw 4vh 1vw;
  z-index: 9;
  background: #FFFFFF;
  border-radius: 10px;
  height: fit-content;
}

.time-over-popup::after,
.time-over-popup::before{
    display: none;
}

.time-over-popup {
  @include mixins.display-flex-space-evenly;
  align-items: center;
  flex-direction: row;
  height: fit-content;
  

  .time-over-illustration {
    width: 8vw;
  }

  .time-over-desc {
    font-family: Roboto;
    font-weight: bold;
    font-size: 1.4vw;
    line-height: 1.6vw;
    padding-left: 0.5vw;
    color: #14324B;

    .close-popup-btn {
      background: #FBEC66;
      width: 10vw;
      height: 4vh;
      border-radius: 40px;
      border: none;
      font-size: 1.1vw;
      font-family: Roboto;
      font-weight: 500;
      color: #0D54BD;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      cursor: pointer;
    }

    .close-popup-btn:hover {
      box-shadow: 0px 0 5px rgba(0, 0, 0, 0.25);
    }
  }
}


.sim-end {
  position: absolute;
  width: 20vw;
  min-height: 67vh;
  height: fit-content;
  background: #FFFFFF;
  left: 0;
  top: 16vh;
  border-top-right-radius: 0.8vw;
  border-bottom-right-radius: 0.8vw;
  
  .end-body {
    @include mixins.display-flex-column;
    padding: 0.7vmax 1vw 0.7vmax 1vw;
    
    .end-message {
      font-family: Roboto;
      font-style: normal;
      font-weight: 600;
      font-size: 1.4vw;
      text-align: center;
      color: #1E2734;
    }

    .end-celebrate {
      width: 25vmin;
      padding: 4vmin 0 4vmin 0;
      align-self: center;
    }

    .end-feedback-title {
      font-family: Roboto;
      font-weight: 400;
      font-size: 1.1vw;
      line-height: 1.4vw;
      text-align: center;
      color: #1E2734;
      margin-bottom: 1.5vh;
    }

    .feedback-btn {
      width: 100%;
      height: 4vh;
      background: #FBEC66;
      font-family: Roboto;
      font-weight: 500;
      font-size: 1.1vw;
      text-transform: uppercase;
      letter-spacing: 0.08em;
      color: #0D54BD;
      border-radius: 20px;
      margin-bottom: 5vh;
    }

    .download-report-title {
      font-family: Roboto;
      font-size: 1.1vw;
      font-weight: 600;
      line-height: 1.4vw;
      text-align: center;
      color: #1E2734;
      margin-bottom: 1.5vh;
    }

    .download-report-btn {
      width: 100%;
      height: 4vh;
      background: #FBEC66;
      font-family: Roboto;
      font-weight: 500;
      font-size: 1.1vw;
      letter-spacing: 0.08em;
      color: #0D54BD;
      border-radius: 20px;
      margin-bottom: 1vh;
    }

    .email-report {
      @include mixins.display-flex;
      width: 100%;
      height: fit-content;
      border: 1px solid #083880;
      border-radius: 20px;
      margin: 1vh 0 1vh 0;

      .input-email {
        width: 85%;
        font-family: Roboto;
        font-size: 1vw;
        color: #1E2734;
        padding-left: 0.5vw;
        
        .MuiInputBase-input {
          height: 4vh;
          padding: 0.5vh;
        }
      }

      .send-email-icon {
        @include mixins.display-flex-center;
        margin-left: auto;
        margin-right: 0.1vmin;
        height: 5vh;
        width: 2vw;
        border-radius: 50%;
        aspect-ratio: 1 / 1;
        background: #0D54BD;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        cursor: pointer;

        .email-report-icon {
          align-self: center;
        }
      }

    }

    #email-report-success {
      display: flex;
      padding-left: 1vw;
      font-family: Roboto;
      font-weight: 400;
      font-size: 0.9vw;
      color: #1E2734;
    }

    #email-report-failed {
      display: flex;
      padding-left: 1vw;
      font-family: Roboto;
      font-weight: 400;
      font-size: 0.9vw;
      color: #1E2734
    }

    #invalid-email {
      padding-left: 1vw;
      font-family: Roboto;
      font-size: 0.9vw;
      font-weight: 500;
      color: #FF7171;

    }
  }

}

/*
  ExitDemoScreen css
*/
.demo-end-screen {
  @include mixins.display-flex;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: fit-content;
  height: fit-content;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #0D54BD;
  border-top-right-radius: 0.8vw;
  border-top-left-radius: 0.8vw;

  .demo-end-content {
    font-family: Roboto;
    font-weight: 400;
    font-size: 1.1vw;
    line-height: 1.4vw;
    padding: 2vh 1vw 1vh 2vw;
    text-align: center;
  }

  .exit-demo-btn {
      background: #FBEC66;
      width: 12vw;
      padding: 0 2vw 0 2vw;
      height: 5vh;
      border-radius: 40px;
      border: none;
      font-size: 1vw;
      font-family: Roboto;
      align-self: center;
      font-weight: 500;
      color: #0D54BD;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      margin-right: 2vw;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
  }
}




/* 
  ProceedDemoScreen css
*/
.proceed-demo-container {
  @include mixins.display-flex-center;

  .demo-card {
    max-width: 60vw;
    width: fit-content;
    height: fit-content;
    opacity: 0.9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0.7vw;
    margin-top: 10vh;
    background: #FFFFFF;

    .proceed-demo-body {
      margin: 5vh 4vw 5vh 4vw;
      max-width: 52vw;
      width: fit-content;
      height: fit-content;

      .demo-message{
        font-family: Roboto;
        font-weight: normal;
        font-size: 1.3vw;
        line-height: 1.8vw;
        padding: 0 0 3vh 0;
        color: #1E2734;
      }

      .demo-msg-note {
        font-family: Roboto;
        font-size: 1.3vw;
        line-height: 1.4vw;
        padding: 0 0 3vh 0;
        color: #0D54BD;
      }

      .demo-navigation-buttons {
        margin-left: 1vw; 

        .play-demo {
          background: #FBEC66;
          width: fit-content;
          padding: 0 2vw 0 2vw;
          height: 4vh;
          border-radius: 40px;
          border: none;
          font-size: 1vw;
          font-family: Roboto;
          font-weight: 500;
          color: #0D54BD;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          margin-right: 3vw;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          cursor: pointer;
        }

        .skip-demo {
          background: transparent;
          border: none;
          margin-right: 1.5vw;
          font-weight: 400;
          font-family: Roboto;
          font-size: 1.1vw;
          width: fit-content;
          color: #0D54BD;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          cursor: pointer;
        }
      }


    }
  }
}



/*TargetAnalyisis Screen */
.target-analysis-container {
  @include mixins.display-flex-column;
  position: absolute;
  background: #FFFFFF;
  width: 60vw;
  height: fit-content;
  top: 20vh;
  left: 20vw;
  border-radius: 10px;
  z-index: 3;

  .confetti1 {
    position: absolute;
    z-index: -1;
    width: 12vw;
    top: 5vh;
    left: 1vw;
    pointer-events: none;
  }
  .confetti2 {
    position: absolute;
    z-index: -1;
    width: 10vw;
    top: 5vh;
    right: 1vw;
    pointer-events: none;
  }

  .target-popup-close {
    position: absolute;
    right: 0.8vw;
    top: 2vh;
    width: 3vw;
    height: 3vw;
    border-radius: 50%;
    cursor: pointer;
  }

  .target-popup-close:hover {
    box-shadow: 0px 0 5px rgba(0, 0, 0, 0.5);
  }

  .target-greeting {
    margin-top: 6vh;
    font-family: Roboto;
    font-weight: 600;
    font-size: 1.9vw;
    color: #083880;
    text-align: center;
    padding-bottom: 2vh;
  }

  .target-comment {
    width: 72%;
    align-self: center;
    font-family: Roboto;
    font-size: 1.4vw;
    line-height: 1.8vw;
    text-align: center;
    padding-bottom: 1vh;
    color: #1E2734;
  }

  .ceo-name {
    font-family: Roboto;
    font-weight: 400;
    font-size: 1.1vw;
    line-height: 1.7vw;
    text-align: center;
    padding-bottom: 2vh;
  }

  .target-illustration {
    width: 14vw;
    align-self: center;
    padding-bottom: 3vh;
  }

  .close-popup-btn {
    background: #FBEC66;
    width: 12vw;
    height: 4vh;
    border-radius: 40px;
    border: none;
    margin: auto 0 3vh 0;
    font-size: 1.1vw;
    font-family: Roboto;
    font-weight: 500;
    color: #0D54BD;
    align-self: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    cursor: pointer;
  }

  .close-popup-btn:hover {
    box-shadow: 0px 0 5px rgba(0, 0, 0, 0.25);
  }


  .target-footer {
    z-index: -1;
    width: 100%;
    position: absolute;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }
}


/* 
  Feedback popup related css 
  Also, all the html and css for feedback popup is provided by kfeedback wrapper so no feedback HTML code in this codebase
*/
#fwc-feedbackComponent .container-popup .popup {
  height: 65%;

  .submit-button-error-message .center-button .btn {
    cursor: pointer;
  }
}