@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.bar-container {
  @include mixins.display-flex-column;
  width: 45%;
  position: relative;

  .bar-content {
    @include mixins.display-flex-space-between;
    
    .bar-label {
      font-weight: 500;
      font-size: 0.75vw;
      color: white;
      align-self: flex-end;
      padding-bottom: 4px;
      text-align: justify;
    }

    .bar-value {
      font-weight: 900;
      font-size: 1.3vw;

      .percentage {
        font-size: 0.9vw;
      }
    }
  }

  .bar-style {
    border-radius: 20px;
    height: 1.3vh;
    border: 0.6px solid #FFFFFF;
    background-color: transparent;
  }

  .impact-indicator {
    z-index: 6;
    @include mixins.display-flex-center;
    width: 6.5vw;
    height: 4.5vh;
    align-self: center;
    border-radius: 40px;

    .indicator-arrow {
      width: 0.6vw;
      padding-right: 0.2vw;
    }

    .impact-value {
      font-family: Roboto;
      color: #012C9A;
      font-weight: 700;
      font-size: 1vw;
      
    }
  }
  // .bar-style > * { background-color: #FF7171; border-radius: 20px;}
}


.animate-impact {
  @include mixins.display-flex;
  width: 6.5vw;
  height: 4.5vh;
  align-self: center;
  top: 4.3vh;
  position: absolute;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animateImpact {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@keyframes fadeInDown {
  0% {
     opacity: 0;
     transform: translateY(-1.5vh);
  }
  100% {
     opacity: 1;
     transform: translateY(0);
  }
} 