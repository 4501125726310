@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.your-team-container {
  @include mixins.display-flex-space-evenly;
  width: 21vw;
  font-family: Roboto;
  margin-left: 1.2vw;
  
  .member-image {
    width: 4vw;
    border-radius: 50%;
    border: 2px solid #0D54BD;
    margin-bottom: 3px;
    cursor: pointer;
  }
  
  .team-member {
    @include mixins.display-flex-column;
    @include mixins.display-flex-center;
    text-align: center;
    width: 5vw;
    justify-content: unset;

    .member-title {
      color: #0D54BD;
      line-height: 1vw;
      font-size: 0.9vw;
      text-align: center;
    }

    .radio {
      padding: 0;
      padding-top: 0.5vh;
      cursor: pointer;
      align-self: center;
    }
  }
}