/* Roboto */
@font-face {
  font-family: 'Roboto';
  src: url(./Roboto/Roboto-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url(./Roboto/Roboto-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url(./Roboto/Roboto-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Roboto';
  src: url(./Roboto/Roboto-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url(./Roboto/Roboto-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* Lato */

@font-face {
  font-family: 'Lato';
  src: url(./Lato/Lato-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url(./Lato/Lato-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url(./Lato/Lato-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url(./Lato/Lato-Black.ttf);
  font-weight: 900;
  font-style: normal;
}