@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';


.hbody{
    padding-top: 2vh;
    .hbhead{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 70%;
        margin-left: 15%;
    }
    .label{
        color: colors.get-color("grey");
        font-size: 1.3vh;
        font-family: Roboto;
        padding-left: 1vw;
        opacity: 0.6;
    }
    .filter{
        align-self: center;
        height: 1vh;
        width: 50%;
        span{
            color: colors.get-color("blue");
            font-size: 2vh;
            font-family: Roboto;
        }
    }
    .reset{
        align-self: center;
        height: 1vh;
        padding-right: 1vw;
        cursor: pointer;
        width: 18%;
        span{
            color: colors.get-color("blue");
            font-size: 2vh;
            font-family: Roboto;
            font-weight: bold;
        }
    }
    .hbbody{
        @include mixins.display-flex-column-reverse;
        padding-top: 2vh;
        .tableRow{
            display: -webkit-inline-flex;
            padding: 1.2vh 0 4vh;
            width: 99%;
            .arrow{
                display: flex;
                flex-direction: column;
                margin-top: "1vh";
                img{
                    height: 1vw;
                    height: 2vw;
                }
            }
            img{
                width: 4vw;
                height: 4vw;
            }

            .indicator-arrow {
                width: 1vw;
                padding-right: 0.2vw;
            }

            .impacted{
                display: flex;
                flex-direction: column;
                width: 80%;
                height: fit-content;
                
                .response{
                    width: 60%;
                    background-color: colors.get-color("white");
                    box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
                    border-radius: 10px;
                    color: colors.get-color("blue");
                    font-size: 1.8vh;
                    line-height: 2.8vh;
                    padding: 1.2vh 1.3vw;
                }
                .res-arrow{
                    content: '';
                    display: block;  
                    // position: absolute;
                    // right: 100%;
                    // top: 50%;
                    // margin-top: -10px;
                    margin-top: 2vh;
                    // background: #FFFFFF;
                    // box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);     
                    width: 0;
                    height: 0;
                    border-top: 1vh solid transparent;
                    border-right: 1vh solid white;
                    border-bottom: 1vh solid transparent;
                    border-left: 1vh solid transparent;
                    filter: drop-shadow(0 4px 0 rgba(31, 72, 174, 0.06));
                    // border-color: rgba(255, 255, 255, 0);
                    // border-right-color: red;
                    // box-shadow:  0 5px -2px #888;
                }
            }
        }
    }
}