@use 'src/styles/_colors.scss';
@use 'src/styles/_mixins.scss';


.info-tabs{
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  margin: 2vh 0 2vh 5%;

  .tab-panel {
    min-height: 3vh;
    border-top-width: 0px;
    border-right-width: 0px;
    border-left-width: 0px;
    border-bottom: 0.5px solid;
    border-image: linear-gradient(to right, rgba(5, 75, 125, 1), rgba(5, 75, 125, 0));
    border-image-slice: 1;

    .tabHead{
      @include mixins.display-flex;
      align-items: flex-start;
      color: colors.get-color("blue")!important;
      font-size: 0.8vw;
      font-weight: 300;
      padding: 0;
      margin-right: 1vw;
      min-width: 6vw;
      min-height: 2vh;
    }
    .MuiTab-textColorPrimary.Mui-selected{
        font-weight: 500;
    }

    .MuiTabs-scroller {
      height: 3vh;
    }
  }

  .client-profile {
    width: 100%;
    height: 90%;
    padding-top: 2vh;

    .level1-info {
      padding-bottom: 2.5vh;

      .info-title {
        font-size: 0.9vw;
        font-weight: 300;
        color: #4F6382;
        border-top-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
        border-bottom: 0.5px solid;
        border-image: linear-gradient(to right, rgba(5, 75, 125, 0.2) 0%, rgba(5, 75, 125, 0)) 100%;
        border-image-slice: 1;
        padding-bottom: 0.8vh;
      }

      .info-content {
        width: 95%;
        height: fit-content;
        padding-top: 2.5vh;


        .revealed-info {
          font-family: Roboto;
          color: #1E2734;
          font-style: normal;
          font-weight: 400;
          font-size: 0.9vw;
          line-height: 2.5vh;
        }

        .hidden-level1-info {
          @include mixins.display-flex-start;

          .reveal-info {
            min-width: 13.5vw;
            width: fit-content;
            height: 4vh;
            font-size: 0.95vw;
            background: #FBEC66;
            border-radius: 20px;
            letter-spacing: 0.08em;
            font-weight: 500;
            color: #0D54BD;
          }

          .action-cost {
            display: flex;
            align-items: center;
            padding-top: 0.8vh;
            line-height: 1.1vw;
            padding-left: 2vw;
        
            .cost-days {
              color: #1E2734;
              font-weight: 500;
              font-size: 0.95vw;
            }
          }
        }
      }
    }

    .level2-info {

      .info-title {
        @include mixins.display-flex;
        .bg-lock {
          padding-right: 1vw;
        }

        font-size: 0.9vw;
        font-weight: 300;
        color: #4F6382;
        border-top-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
        border-bottom: 0.5px solid;
        border-image: linear-gradient(to right, rgba(5, 75, 125, 0.2) 0%, rgba(5, 75, 125, 0)) 100%;
        border-image-slice: 1;
        padding-bottom: 0.8vh;
      }

      .info-content {
        width: 95%;
        height: fit-content;
        padding-top: 2.5vh;


        .revealed-info {
          font-family: Roboto;
          color: #1E2734;
          font-style: normal;
          font-weight: 400;
          font-size: 0.95vw;
          line-height: 2.5vh;
        }

        .hidden-level2-info {
          min-height: fit-content;
          width: 32vw;
          height: 4.5vh;
          margin-top: 0;

          .tooltip {
            font-style: italic;
            font-weight: 400;
            color: #4F6382;
            font-size: 0.85vw;
            line-height: 1.2vw;
          }
        }
      }
    }
  }

  .individual-history {
    overflow-y: scroll;
    max-height: 57vh;
    
    .ind-history-container {
      @include mixins.display-flex-column-reverse;

      .client-history-card {
        @include mixins.display-flex;
        height: fit-content;
        padding-bottom: 1vh;
        padding-top: 1vh;
        border-bottom: 1px solid rgb(1, 44, 154,0.2);

        .hcard-desc {
          @include mixins.display-flex-column;
          width: fit-content;
          padding-right: 0.7vw;

          .hcard-title {
            @include mixins.display-flex;
            width: fit-content;
            font-size: 1.1vw;
            line-height: 1.5vw;
            color: #0D54BD;
            padding-bottom: 1vh;
            text-transform: uppercase;

            .title-day {
              width: calc(3.9vw - 0.5px);
              white-space: nowrap;
              font-weight: 300;
              padding-right: 0.5vw;
              border-right: 1px solid #0D54BD;
            }

            .title-action {
              padding-left: 0.5vw;
              font-weight: 500;
            }
          }

          .hcard-body {
            @include mixins.display-flex;
            
            .img-with-name {
              @include mixins.display-flex-column;
              justify-content: center;
              align-items: center;
              margin-right: 1vw;

              .hcard-img {
                width: 3vw;
                height: 3vw;
                margin-right: 1.3vw;
                align-self: center;
              }

              .hcard-img-name {
                font-family: Roboto;
                font-size: 0.9vw;
                font-weight: 400;
                color: #1E2734;
                padding-top: 0.5vh;
              }
            }

            .hcard-img {
              width: 3vw;
              height: 3vw;
              margin-right: 1vw;
              align-self: center;
            }
            
            .response-with-name {
              @include mixins.display-flex-column;
              border-left: 1px solid rgb(13, 84, 189,0.2);

              .hcard-response {
                font-family: Roboto;
                font-size: 1.1vw;
                color: #1E2734;
                line-height: 1.4vw;
                padding-left: 0.5vw;
              }

              .hcard-response-name {
                font-family: Roboto;
                font-size: 0.95vw;
                font-weight: 300;
                color: #1E2734;
                padding-top: 0.5vh;
                padding-left: 0.5vw;
              }
            }
          }


        }

        .history-impact {
          @include mixins.display-flex;
          margin-left: auto;

          .impact-you {
            @include mixins.display-flex-column;
            width: 4.5vw;
            padding-right: 0.5vw;

            .impact-label {
              font-family: Roboto;
              font-weight: 500;
              font-size: 1vw;
              text-align: center;
              line-height: 1.5vw;
              color: #0D54BD;
            }
            .indicator-container {
              @include mixins.display-flex;
              align-items: center;
              height: 100%;
              
              .impact-indicator {
                @include mixins.display-flex-center;
                width: 4.5vw;
                height: 4.5vh;
                align-items: center;
                border-radius: 30px;

                .indicator-arrow {
                  width: 0.8vw;
                  padding-right: 0.2vw;
                }

                .impact-value {
                  font-family: Roboto;
                  font-weight: 700;
                  font-size: 1.1vw;
                }
              }
            }
          }
        }
      }
    }
  }

  .indicator{
    background: linear-gradient(270deg, colors.get-color("blue") 51.43%, colors.get-color("blue") 51.43%);
    border-bottom-width: 0px;  
    border-top-width: 0px; 
    border-right-width: 0px; 
    border-left-width: 0px;
    border-image-slice: 1;
    font-weight: 500;
    background-color: initial !important;
    width: 1vw;
  }
}



