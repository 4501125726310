@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.pcontainer {
  min-width: 45vw;
  max-height: 70vh;
  width: fit-content;
  // display: table;  /*add this line for edge*/
  // height: fit-content;
  height: min-content; // firefox not supporting fit-content
  position: absolute;
  margin: auto; //8vmin auto auto 15vmax;
  top: 1vh;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to right, #2E78BD -23.62%, #054B7D 103.5%), linear-gradient(to bottom, #EDEDED 2.8%, #FFFFFF 114.93%);
  border-radius: 0.7vw;
  font-family: Roboto;

  //  @supports (height: -moz-fit-content) { /* workaround because firefox doesn't support height: auto with height: fit-content; */
  //   .pcontainer {
  //       // height: 52vmin;
  //   }
  //  }

  .phead {
    @include mixins.display-flex-center;
    background: linear-gradient(to right, #2E78BD -23.62%, #054B7D 103.5%), linear-gradient(to bottom, #EDEDED 2.8%, #FFFFFF 114.93%);
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.05);
    min-height: 10vh;
    border-radius: 0.7vw 0.7vw 0 0;  
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 2.2vh;
  }

  .curved-header {
    background: #FFFFFF;
    height: 1vh;
    border-top-left-radius: 50% 100%;
    border-top-right-radius: 50% 100%;
    margin-top: -1vh;
  }

  .pbody {
    background: #FFFFFF;
    max-height: 66vh;
    height: 100%;
    border-bottom-left-radius: 0.7vw;
    border-bottom-right-radius: 0.7vw;
    padding-left: 3vw;
    padding-bottom: 2vw;
    overflow-y: auto;
    background-image: url('../../../assets/images/popup_footer.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;

    // .pfooter {
    //   width: auto;
    //   position: relative;
    //   bottom: 0;
    //   margin-top: auto;
    // }
  }

  .popup-close:hover {
    box-shadow: 0px 0 5px rgba(0, 0, 0, 0.5);
  }

  

}

