@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.powermap{
    transition-duration: 0.6s;
}
.expand-powemap{
    width: 60vw;
    height: 60vh;
    left: 40vw;
    position: absolute;
}
// ::placeholder class
%powermap-position{
    position: fixed;
    top: 29vh;
    right: -1vw;
}

.powermap-btn{
    @extend %powermap-position;
    @include mixins.display-flex-center;
    align-items: flex-start;
    width: 8vw;
    height: 7vh;
    border-image: linear-gradient(270deg, rgba(5, 75, 125, 0) 0%, #054B7D 51.43%, rgba(5, 75, 125, 0) 98.53%);
    border-bottom: 0px;   
    border-right: 0px; 
    border-left: 0px;
    border-image-slice: 1;
    transform: rotate(-90deg);
    font-weight: 500;
    font-size: 1.2vw;
    font-family: Roboto;
    padding-top: 0.5vw;
    right: -3vw;
    color: #083880;
    background: transparent;
    cursor: pointer;
}

.dash-pm{
    z-index: 3;
    position: relative;
    display: flex;
    height: 65vh;
    width: 60vw;
    .container{
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
        border-radius: 1vw;
        margin-right: 0.2vw;
        width: 70%;
    }
    
}
.powermap-btn-overlay{
    @extend .powermap-btn;
    right: 57vw;
    z-index: 3;
}
.influence-container{
    width: 30%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
    border-radius: 10px;
    height: 65vh;
    font-family: 'Roboto';
    .tool-container{
        height: -webkit-fill-available;
        border-radius: 10px;
        margin: 1vh 0;
    }
    .heading{
        display: flex;
        span{
            color: colors.get-color("blue");
            font-family: 'Roboto';
            font-size: 1.8vh;
            margin-left: 0.6vw;
            align-self: center;
        }
        img{
            margin-left: auto;
            margin-right: 0.3vw;
        }
    }
    .step-1{
        padding-left: 0.5vmin;
        padding-top: 0.3vmin;
        span{
            padding-left: 0.5vmin;
            font-size: 1.7vmin;
            opacity: 0.8;
        }
        .from{
            padding-top: 1vh;
            color: colors.get-color("grey");
            .text{
                font-size: 1.4vh;
                opacity: 0.6;
            }
        }
        .to{
            padding-top: 1vh;
            color: colors.get-color("grey");
            .text{
                font-size: 1.4vh;
                opacity: 0.6;
            }
        }
    }
    .step-2{
        padding-left: 0.5vw;
        padding-top: 1.5vh;
        .heading{
            // padding-left: 0.3vw;
            font-size: 1.8vh;
            opacity: 0.8;
        }
    }
    .influence{
        padding-top: 1vh;
        color: colors.get-color("grey");
        padding-left: 1vw;
        .text{
            font-size: 1.4vh;
            opacity: 0.6;
        }
    }
    .slider{
        width: 90%;
        height: 0.8vh;
        padding: 1vh 0;
    }
    .checkbox{
        background-color: #E2EEFF;
        padding: 0;
        width: 1vw;
        height: 1.5vh;
        display: inline-block;
        margin-right: 0.4vw;
    }
    .text-check{
        @extend .text;
        color: colors.get-color("blue");
        opacity: 1 !important;
    }
    .arrow{
        cursor: pointer;
        width: 1vw;
        height: 0.5vw;
    }
    .checked{
        background-color: #E2EEFF;
        padding: 0;
        display: inline-block;
        margin-right: 0.4vw;
    }

    .pm-buttons{
        padding-left: 0.5vw;
        padding-top: 1.5vh;
        text-transform: uppercase;
        display: -webkit-inline-box;    
        .draw{
            color: colors.get-color("blue");
            background-color: colors.get-color("yellow");
            box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
            border-radius: 2vh;
            border: none;
            padding: 1vh 6vw;
            font-size: 1.7vh;
            font-weight: 500;
            font-family: Roboto;
            cursor: pointer;
        }
        .remove{
            color: colors.get-color("blue");
            border: none;
            background: none;
            margin-left: 0.8vw;
            padding: 1vh 6vw;
            font-size: 1.7vh;
            font-weight: 500;
            font-family: Roboto;
            cursor: pointer;
        }
        
    }
    
}
.disabled{
    color: colors.get-color("blue");
    opacity: 0.6;
    pointer-events: none;
}
.curved-header {
    background: #FFFFFF;
    height: 1vh;
    border-top-left-radius: 50% 100%;
    border-top-right-radius: 50% 100%;
    margin-top: -1vh;
  }
.desc-container{
    z-index: 6;
    position: absolute;
    margin: auto;
    top: 1vh;
    bottom: 0;
    left: 0;
    right: 0;
    .desc-header{
        background: linear-gradient(to right, #2E78BD -23.62%, #054B7D 103.5%), linear-gradient(to bottom, #EDEDED 2.8%, #FFFFFF 114.93%);
        // border-radius: 0.7vw;
        border-top-left-radius: 0.7vw;
        border-top-right-radius: 0.7vw;
        font-family: Roboto;
        height: 10vh;
        text-align: center;
        font-family: Roboto;
        font-size: 2.5vh;
        color: #FFFFFF;
    }
    .desc-body{
        height: fit-content;
        border-bottom-left-radius: 0.7vw;
        border-bottom-right-radius: 0.7vw;
        background: #FFFFFF;
        height: -webkit-fill-available;
        padding: 4vh 2vh;
        font-family: Roboto;
        font-size: 1.8vh;
        line-height:2vh;
        color: colors.get-color("grey");
        overflow: auto;
        .steps {
            font-weight: bold;
        }
    }
}
