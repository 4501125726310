@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #888;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.8vw;
}

*::-webkit-scrollbar-track {
  background: rgba(75, 82, 93, 0.1);
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background: rgb(75, 82, 93);
  border-radius: 20px;
  border-bottom: 0.2vw solid rgba(75, 82, 93, 0.1);
  border-left: 0.2vw solid rgba(75, 82, 93, 0.1);
  border-right: 0.2vw solid rgba(75, 82, 93, 0.1);
  border-top: 0.2vw solid rgba(75, 82, 93, 0.1);
  background-clip: padding-box;
}

.dashboard-container {
  width: 100vw;
  height: 100vh;
  z-index: 1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  @include mixins.display-flex-column;
  
  .header {
    @include mixins.display-flex-center;
    width: 100vw;
    height: 15vh ;
    background: linear-gradient(152.84deg, #2E78BD 28.78%, #054B7D 73.94%), linear-gradient(180deg, #EDEDED 2.44%, #FFFFFF 100%);
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.05);
    // border-radius: 0 0 20px 20px;
    border-bottom-left-radius: 40% 10%;
    border-bottom-right-radius: 40% 10%;
    font-family: Roboto;
  }

  .dashboard-body{
      display: flex;
  }
  
  .dashboard-footer {
    @include mixins.display-flex-end;
    margin-top: auto;
    margin-bottom: 1vh;
    #dashboard-your-team {
      @include mixins.display-flex;
      cursor: pointer;
      
      .team-title {
        @include mixins.border-vertical-blue;
        @include mixins.display-flex-center;
        width: 5vw;
        color: #054B7D;
        font-family: Roboto;
        font-size: 1.1vw;
        font-weight: 400;
        height: auto;
        align-self: stretch;
        text-align: center;
        border-top-width: 0px;
        border-left-width: 0px;
        border-bottom-width: 0px;
        border-right: 1.5px solid;
        text-transform: uppercase;
      }
    }
   
  }

}