@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.client-container{
    width: 55vw;
    margin-top: 11vh;
    font-family: Roboto;
    font-weight: 400;
}

.client-container::after,
.client-container::before{
    display: none;
}

// ::placeholder class
%client-row{
    display: flex; 
    justify-content: center;
    justify-content: space-around; // space-evenly not supported in edge
}

.client-row-1{
    @extend %client-row;
}
.client-row-3{
    @extend %client-row;
}
.client-row-5{
    @extend %client-row;
}
.client-row-2{
    @extend %client-row;
    margin-top: -1.5vh;
    width: 80%;
}
.client-row-4{
    @extend %client-row;
}
// .divider{
//     background: linear-gradient(92.13deg, #2E78BD 0.83%, #054B7D 100%), linear-gradient(180deg, #EDEDED 2.44%, #FFFFFF 100%);
//     height: 3.3vh !important;
// }
// .divider-horiz{
//     background: linear-gradient(92.13deg, #2E78BD 0.83%, #054B7D 100%), linear-gradient(180deg, #EDEDED 2.44%, #FFFFFF 100%);
//     width: 30%;
//     height: 1px !important;
//     margin-top: 30px !important;
// }

// Character Container 
// ::placeholder class

%character-container{
    border-top-left-radius: 50% 20%;
    border-top-right-radius: 50% 20%;
    background-color: white;
    width: 15.5vw;
    height: fit-content;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; 
    cursor: pointer;
    .actor-image{
        height: 7vh;
        width: 3.5vw;
        margin-top: -1vh;
        border-radius: 20%;
    }  
   
}
.select-icon{
    display: none;
}
.actorSelected{
    display: block;
    top: 0.5vh;
    left: 2vw;
    height: 2vh;
    width: 2vw;
    position: relative;
    z-index: 3;
}


.character-container{
    @extend %character-container;
}
.select {
    -moz-box-shadow: 0 -0.2vh 0.2vh #00b2ff;
    -webkit-box-shadow: 0 -0.2vh 0.2vh #00b2ff;
    box-shadow: 0 -0.2vh 0.2vh #00b2ff;
}

.client-connectors{
    display: flex;
}
.client-name{
    font-family: Roboto;
    font-weight: 400;
    font-size: 1.9vh;
    color: #083880;
    padding: 2vh 0 0 0.3vw
}
.client-designation{
    font-family: Roboto;
    font-weight: 300;
    font-size: 1.6vh;
    color: #083880;
    padding: 0 0 0 0.3vw;
}
.progress-meter{
    background: linear-gradient(92.13deg, #2E78BD 0.83%, #054B7D 100%), linear-gradient(180deg, #EDEDED 2.44%, #FFFFFF 100%);
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.05);
    border-top-left-radius: 50% 10%;
    border-top-right-radius: 50% 10%;  
    height: 6vh;
    border-bottom-left-radius: 1vh;
    border-bottom-right-radius: 1vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1vh 0.5vw 0px 0.5vw;
    
    .progress-bar{
        display: flex;
        flex-direction: row;
        font-size: 0.73vw;
        font-weight: bold;
        color: #FFFFFF;
        align-items: center;   
        .client-bar {
            @include mixins.display-flex-center;
            overflow: hidden;
            border-radius: 0.5vh;
            margin-left: 0.2vw;
            height: 1vh;

            .progress-bar-style{
                width: 11vw;
                overflow: visible;
                height: 0.5vh;
                border-radius: 0.5vh;
            }
        } 
        
    }
}

.character-header {
display: flex;
height: 7vh;
position: relative;
}
