@use 'src/styles/_colors.scss';

.intro-container{
    // x scrollbar will be added auto if there's a y scroll (since y scrollbar will occupy bit space)
    height: 100vh;
    width: 100%;
}
.intro-header{
    display: flex;
    height: 15vh;
    // width: 100vw;
}
.logout{
    margin-left: auto;
    color: colors.get-color("blue");
}
.landing-body{
    display: flex;
    margin: 10vh 5vw 0 12vw;
    .content{
        margin: 5vh 2vw 5vh 4vw;
    }
    .welcome{
        font-size: colors.get-font("xxl");;
        color: colors.get-color("black");
        font-family: 'Roboto';
        margin-bottom: 3vh;
        .trust{
         color: colors.get-color("blue");
        }
        .sim{
            color: #F4E239;
            font-style: italic;
        }
    }
    .desc{
        color: colors.get-color("black");
        font-family: 'Roboto';
        font-size: colors.get-font("m");
        line-height: 4vh;
        margin-bottom: 2vh;
    }
    
    .ready-text{
        color: colors.get-color("blue");
        font-size: colors.get-font("l");
        font-weight: 500;
        font-family: 'Roboto';
    }
    .ready{
        color: colors.get-color("blue");
        background-color: colors.get-color("yellow");
        box-shadow: 0px 4px 4px rgba(31, 72, 174, 0.06);
        border-radius: 2vh;
        border: none;
        padding: 1.5vh 10vw;
        font-size: colors.get-font("m");
        font-weight: 500;
        font-family: 'Roboto';
        text-transform: uppercase;
        margin-top: 3vh;
        cursor: pointer;
    }
}

.intro-footer{
    pointer-events: none;
    bottom: -1vmax;
    position: fixed;
}