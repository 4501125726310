.pbody .lbutton{
    padding-top:3vh;
    padding-bottom:1vh;
    margin-left: 0;
    display: flex;
    width: 100%;
    .next{
        margin-left: auto;
        margin-right: 5vw;
        width: 12vw;
    }
}
.error{
    color: red;
    font-size: 1.2vmax;
    text-transform: none;
    width: 50%;
    margin-left: 5vw;
    padding-top: 1vh;
}
.metric-popup-icon {
    display: flex;
    height: 2vw;
    width: 2vw;
    padding-right: 0.5vw;
    justify-content: center;
  }