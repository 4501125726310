.fake-div {
.container-circle {
  width: 100vw;
  height: auto;

  .sk-circle {
    width: 20px;
    height: 20px;
    top: 40%;

    .sk-circle-dot:before {
      background-color: #0D54BD;
    }
  }
}
}