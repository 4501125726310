@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.action {
  width: 6vh;
  height: 6vh;
  background: linear-gradient(to right, rgba(87, 173, 254, 1), rgba(46, 120, 189, 1));
  border-radius: 50%;
  @include mixins.display-flex-center;
  cursor: pointer;
  position: relative;
  user-select: none;

  /*
  .action-name-hover {
    display: none;
    position: absolute;
    left: 7vh;
    min-width: 7.5vw;
    width: fit-content;
    min-height: 4vh;
    height: fit-content;
    box-sizing: border-box;
    padding: 0 1vw 0 1vw;
    font-family: Roboto;
    font-size: 1vw;
    font-weight: 400;
    line-height: 1vw;
    white-space: nowrap;
    background: #FFFFFF;
    color: #0D54BD;
    border-radius: 20px;
  }
  */
}

.group-action-name {
  font-family: Roboto;
  font-weight: 400;
  font-size: 0.95vw;
  color: #0D54BD;
  padding-top: 0.5vh;
  padding-bottom: 1vh;
  text-transform: uppercase;
}

.actions:not(.no-hover) > .action:hover > .action-name-hover {
  @include mixins.display-flex-center;
}

.clicked {
  background: linear-gradient(341.24deg, #EFF5FF 12.68%, #FFFFFF 87.32%) !important;
}