@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.impact-indicator {
  @include mixins.display-flex-center;
  width: 3.5vw;
  height: 2.7vh;
  align-items: center;
  border-radius: 10px;
  
  .indicator-arrow {
    width: 0.5vw;
  }

  .impact-value {
    font-family: Roboto;
    color: #012C9A;
    font-weight: 700;
    font-size: 0.9vw;
  }


  
}