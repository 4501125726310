@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.pcontainer {

  .ohead {
    height: 9.5vh;
    font-family: Roboto;
    .oaction-name {
      @include mixins.display-flex-center;

      .oaction-icon {
        width: 1.6vw;
        padding-right: 0.8vw;
      }

      .oaction-title {
        line-height: 1.8vh;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.1vw;
        font-size: 1.2vw;
        color: #FFFFFF;
      }
    }
  }

  .obody {
    @include mixins.display-flex-column;
    width: 52vw;
    min-height: 36.5vh;
    padding-left: 0;
    padding-bottom: 0;
    max-height: 70vh;
    overflow-y: auto;
    font-family: Roboto;
    // padding: 0 4vw 2vw 4vw;

    .oaction-characters {
      @include mixins.display-flex-center;
      width: auto;
      height: 7vh;
      padding-top: 2vh;

      .ofrom-character {
        width: 3.4vw;
        padding-right: 0.8vw;
      }

      .oarrow-right {
        width: 4.5vw;
        padding-right: 0.8vw;
      }


      .oto-character {
        width: 3.4vw;
        padding-right: 0.8vw;
      }
      
    }

    .option-container {
      @include mixins.display-flex-column;
      padding: 4vh 4vw 3vh 3vw;
      
      .select-option-title {
        font-family: Roboto;
        font-weight: 500;
        font-size: 1.1vw;
        line-height: 1.5vh;
        color: #0D54BD;
      }

      .olist {
        @include mixins.display-flex-column;
        padding-top: 2vh;
        padding-left: 0.5vw;

        .option {
          @include mixins.display-flex;
          font-family: Roboto;
          font-weight: 400;
          font-size: 1.1vw;
          color: #083880;
          line-height: 1.6vw;
          padding-bottom: 1vh;

          .oradio {
            align-self: flex-start;
            height: 2vw;
            padding: 0;
            padding-right: 1vw;
          }

          .option-text {
            cursor: pointer;
          }
        }

        .option:hover {
          background: rgba(13, 84, 189, 0.06);
        }
      }
    }

    .otake-action-button {
      background: #FBEC66;
      border-radius: 20px;
      height: 4vh;
      width: 14vw;
      margin-top: 2vh;
      color: #0D54BD;
      align-self: center;
      font-size: 1.1vw;
      font-weight: 500;
      cursor: pointer;
    }

    .ocancel-action-button {
      background: transparent;
      box-shadow: none;
      font-weight: 500;
      border-radius: 20px;
      height: 4vh;
      align-self: center;
      width: 14vw;
      font-size: 1.1vw;
      color: #0D54BD;
      cursor: pointer;
      border: 1.5px solid #FBEC66;
      margin-top: 1vh;
      margin-bottom: 3.5vh;
    }

    .ocancel-action-button:hover {
      box-shadow: 0px 0 4px rgba(0, 0, 0, 0.25);
    }

    // .pfooter {
    //   width: auto;
    // }
  }
}
