@use 'src/styles/_mixins.scss';
@use 'src/styles/_colors.scss';

.overlay {
  position: fixed; /* Sit on top of the page content */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // display: none; /* Hidden by default */
  width: 100vw; 
  height: 100vh;
  background: linear-gradient(152.84deg, rgba(213, 213, 213, 0.7) 28.78%, rgba(47, 99, 137, 0.7) 73.94%);
  backdrop-filter: blur(4px);
  z-index: 2;
}